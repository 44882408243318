import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import Form from './Form';
import { useAuth } from 'hooks/use-auth';
import { removeUser, setUser } from 'store/slices/userSlice';
import { useHistory } from 'react-router-dom';


const Login = () => {
	const dispatch = useDispatch()
	const [err, setErr] = useState('')
	const { isAuth, email } = useAuth()
	const { push } = useHistory()

	const handleLogin = (email, password) => {
		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password)
			.then(({ user }) => {
				dispatch(setUser({
					email: user.email,
					token: user.accessToken,
					id: user.uid
				}))
				push('/')
			})
			.catch(error => {
				console.log("ERROR OCCURED -> ", error)
				setErr("Wrong password! Try again.")
			})
	}
	const logOut = () => {
		dispatch(removeUser())
		push('/login')
	}

	return isAuth
		?
		(
			<>
				<div>Hello, {email}</div>
				<button onClick={logOut}>Log Out</button>
			</>
		)
		:
		(
			<>
				<Form
					title="Sign In"
					handleClick={handleLogin}
					errorMsg={err}
				/>
			</>
		);
}

export default Login;