import { useAuth } from 'hooks/use-auth';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { removeUser } from 'store/slices/userSlice';

import './style.sass'


const HomePage = () => {
	const dispatch = useDispatch()
	const { isAuth, email } = useAuth()
	const {push} = useHistory()

	const logOut = () => {
		dispatch(removeUser())
		push('/login')
	}

	return isAuth
		?
		(
			<>
				<div>Hello, {email}</div>
				<button onClick={logOut}>Log Out</button>
			</>
		)
		:
		(
			<>
				<Redirect exact to='/login' />
				<div className='top'>
					<ul>
						<li><NavLink to='/'>Home</NavLink></li>
						<li><NavLink to='/login'>Login</NavLink></li>
						<li><NavLink to='/register'>Register</NavLink></li>
					</ul>
				</div>



			</>
		);
}

export default HomePage;