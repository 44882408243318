import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/index.js';
import './firebase'

ReactDOM.render(

	<BrowserRouter basename='/login'>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,

	document.getElementById('wrapper')
);