import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import Form from './Form';
import { setUser } from 'store/slices/userSlice';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
	const dispatch = useDispatch()
	const { push } = useHistory()
	const [err, setErr] = useState('')

	const handleRegister = (email, password) => {
		const auth = getAuth();
		createUserWithEmailAndPassword(auth, email, password)
			.then(({ user }) => {
				dispatch(setUser({
					email: user.email,
					id: user.uid,
					token: user.accessToken
				}));
				push('/');
			})
			.catch(error => {
				console.log("ERROR OCCURED -> ", error)
				setErr("This e-mail have been registered. Create a new one.")
			})
	}

	return (
		<>
			<Form
				title="Registry"
				handleClick={handleRegister}
				errorMsg={err}
			/>
		</>
	);
}

export default SignUp;