import React, { useState } from 'react';


const Form = ({title, handleClick, errorMsg}) => {
	const [email, setEmail] = useState('')
	const [pass, setPass] = useState('')

	return (
		<>
			<input
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				onClick={(e) => setEmail(e.target.value)}
				placeholder="e-mail"
			/>
			<input
				type="password"
				value={pass}
				onChange={(e) => setPass(e.target.value)}
				onClick={(e) => setPass(e.target.value)}
				placeholder="password"
			/>
			<button
				onClick={() => handleClick(email, pass)}
			>
				{title}
			</button>
			<span className='errorMessage'>&nbsp;&nbsp;&nbsp;{errorMsg}</span>
		</>
	);
}

export default Form;