import SignUp from 'components/SignUp';
import React from 'react';
import { Link } from 'react-router-dom';


const RegisterPage = () => {
	return (
		<>
			<h1>Register</h1>
			<SignUp />
			<p>
				Already have an account? <Link to={'/login'}>Sign In</Link>
			</p>
		</>
	);
}

export default RegisterPage;