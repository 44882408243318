import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import RegisterPage from 'pages/RegisterPage';
import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';

const App = () => {
	return (
		<Switch>
			<Route exact path='/' component={HomePage} />
			<Route exact path='/login' component={LoginPage} />
			<Route exact path='/register' component={RegisterPage} />
		</Switch>
		
	);
}

export default App;
